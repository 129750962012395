import React from 'react'
import { useNavigate } from 'react-router-dom';
import { UserType, isUserPermited } from '../../../session';
import { useAppContext } from '../../../AppContextProvider ';
import { getArtStatClass, getCycleTypeColor, getEnumValueById, getTextColor } from '../../../common';

export default function MagnetSidebar(props) {
    const theme = useAppContext().themeDetails;
    let navigate = useNavigate();
    var callFromAction = props.callFromAction ? true : false;
    return (
        <>
            {callFromAction == false ?


                < div className={theme.bg_Light + ' ' + theme.thumb + ' ' + theme.track + ' duration-150 overflow-x-auto  p-2 rounded-md h-full scrollbar scrollbar-thin'}>

                    <div className={'   cursor-pointer  hover:text-white py-3 text-left p-2 font-medium mb-2	flex flex-col  justify-center propss-center border-b'}>

                        <div className=' text-sbase  text-gray-700 flex items-center flex-col'>
                            {getEnumValueById(props.ProcedureTypeId) === "---" ? '' : (
                                <div className={`flex flex-col h-12 w-12 shadow rounded items-center justify-center  `}
                                    style={{ backgroundColor: (getCycleTypeColor(getEnumValueById(props.ProcedureTypeId)?.split(' ')[0]?.trim()) || 'gray') }}>
                                    <label className={` text-sm text-center font-semibold  w-10 overflow-hidden  text-${getTextColor(getCycleTypeColor(props.ProcedureTypeId))}`}>{getEnumValueById(props.ProcedureTypeId)}</label>
                                    <div className="rounded bg-white flex  items-center justify-center  h-4 w-8 my-0.5" >{props.TPVersion && <label className={`  text-xss font-bold   text-gray-600`}>v{props.TPVersion} </label>}</div>
                                </div>
                            )}
                            <label className='px-2 font-semibold text-center pt-2'>{props?.ProcedureName}</label>
                        </div>

                        {/* <label className={getArtStatClass(props?.CycleStatus) + '  text-base pt-1 font-bold'}>{props?.CycleStatus}</label> */}
                        {/* <label className={getArtStatClass(props?.CycleStatus) + '  text-base pt-1 font-bold'}>{props?.ProcedureName}</label> */}
                        {/* <label className={`${theme.txt_color} text-gray-500 text-sbase text-center font-medium text-xl`}>{'ART-' + props.artDetails?.ProcedureName}</label> */}
                        <label className={`${theme.txt_color} text-gray-500 text-sbase text-center font-medium text-xl`}>{'ART-' + props.artid}</label>
                    </div>
                    <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                        Overview
                    </div>
                    <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                        Actions
                    </div>
                    <div className={props.ActiveItem === 'TreatmentPlan' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium')} onClick={() => navigate('../treatmentplan', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                        Treatment Plan
                    </div>
                    <div className={props.ActiveItem === 'Scans' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium')} onClick={() => navigate('../scans', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                        Scans
                    </div>

                    <div className={props.ActiveItem === 'BloodResult' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium')} onClick={() => navigate('../bloodResult', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                        Blood
                    </div>
                    <div className={props.ActiveItem === 'BookingForm' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium')} onClick={() => navigate('../bookingform', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                        Booking Form
                    </div>
                    {isUserPermited(UserType.Administrator) && <div className={props.ActiveItem === 'Ohss' ? (theme.itemActive + ' rounded text-sm cursor-pointer  mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer mt-1 text-left p-2 font-medium')} onClick={() => navigate('../ohss', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                        OHSS
                    </div>}
                    <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                        In-Cycle Notes
                    </div>
                    <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                        ART
                    </div>
                    <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                        LAB
                    </div>

                    <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                        Logs
                    </div>
                    <div className={theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer  hover:text-white mt-1  text-left p-2 font-medium	hidden'}>
                        In-Cycle Notifications
                    </div>
                    {/* <div className={props.ActiveItem === 'AddReferenceItem' ? (theme.itemActive +'  rounded text-sm cursor-pointer  hover:text-white mt-1 color-green text-left p-2 font-medium') : ( theme.side_Br_Hover +' bg-white rounded text-sm cursor-pointer  hover:bg-green-700 hover:text-white mt-1  text-left p-2 font-medium')} onClick={() => navigate('../addreferenceitems', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView:IsCalledByView  } })}>
                Add Reference Items
            </div> */}

                    {/* {!isUserPermited(UserType.Nurse) ?
                <div className={props.ActiveItem === 'UpdateTreatmentPlan' ? (theme.itemActive + '  rounded text-sm cursor-pointer  hover:text-white mt-1 color-green text-left p-2 font-medium') : (theme.side_Br_Hover + ' bg-white rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white mt-1  text-left p-2 font-medium')} onClick={() => navigate('../updateTreatmentPlan', { state: { artid: props.artid, coupleid: props.coupleid, IsCalledByView: props.IsCalledByView } })}>
                    + Add Treatment Plan
                </div>
                : ''} */}

                </div >
                :
                < div className={theme.bg_Light + ' ' + theme.thumb + ' ' + theme.track + ' duration-150 overflow-x-auto  p-2 rounded-md h-full scrollbar scrollbar-thin'}>

                    <div className={'   cursor-pointer  hover:text-white py-3 text-left p-2 font-medium mb-2	flex flex-col  justify-center propss-center border-b'}>

                        <div className=' text-sbase  text-gray-700 flex items-center flex-col'>
                            {getEnumValueById(props.ProcedureTypeId) === "---" ? '' : (
                                <div className={`flex flex-col h-12 w-12 shadow rounded items-center justify-center  `}
                                    style={{ backgroundColor: (getCycleTypeColor(getEnumValueById(props.ProcedureTypeId)?.split(' ')[0]?.trim()) || 'gray') }}>
                                    <label className={` text-sm text-center font-semibold  w-10 overflow-hidden  text-${getTextColor(getCycleTypeColor(props.ProcedureTypeId))}`}>{getEnumValueById(props.ProcedureTypeId)}</label>
                                    <div className="rounded bg-white flex  items-center justify-center  h-4 w-8 my-0.5" >{props.TPVersion && <label className={`  text-xss font-bold   text-gray-600`}>v{props.TPVersion} </label>}</div>
                                </div>
                            )}
                            <label className='px-2 font-semibold text-center pt-2'>{props?.ProcedureName}</label>
                        </div>

                        {/* <label className={getArtStatClass(props?.CycleStatus) + '  text-base pt-1 font-bold'}>{props?.CycleStatus}</label> */}
                        {/* <label className={getArtStatClass(props?.CycleStatus) + '  text-base pt-1 font-bold'}>{props?.ProcedureName}</label> */}
                        {/* <label className={`${theme.txt_color} text-gray-500 text-sbase text-center font-medium text-xl`}>{'ART-' + props.artDetails?.ProcedureName}</label> */}
                        <label className={`${theme.txt_color} text-gray-500 text-sbase text-center font-medium text-xl`}>{'ART-' + props.artid}</label>
                    </div>
                </div>
            }
        </>
    )
}
