import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { useAppContext } from '../../../../AppContextProvider ';
import { formatTime, formatTimeDifference, getCorrectDate, getCycleTypeColor, getEnumValueById, getTextColor, replaceEmptyObjectsWithNull } from '../../../../common';
import { ApiCall_GET, ApiCall_POST } from '../../../../Redux/Generic/action';
import APIURLS from "../../../../Common/APIURLS"
import AddNurseAction from './AddNurseAction';
import ActionItem from './ActionItem';
import AddIcon from '@mui/icons-material/Add';

export const NurseAction = ({ ApiCall_POST, ApiCall_GET, props, coupleid, }) => {
  const theme = useAppContext().themeDetails;
  const [activeStep, setActiveStep] = useState(true);
  const [nursingActionData, setNursingActionData] = useState('');
  const [nursingActionItem, setNursingActionItem] = useState('');
  const [openModal, setOpenModal] = useState(0);

  useEffect(() => {
    ApiCall_GET(APIURLS.nursingActionUrls.GetNursingActionDetails, true).then(function (payload) {
      setNursingActionData(replaceEmptyObjectsWithNull(payload))
    });
  }, []);

  return (
    <div className='w-full  bg-white p-2'>
      <div className={'pl-2 py-2 rounded flex items-center justify-between '}>
        <div className=''>  <label onClick={() => { setActiveStep(true); }} className={'mr-4 font-bold cursor-pointer ' + (activeStep ? (theme.txt_color + ' border-b-[3px] border-b-amber-500') : 'text-gray-400  border-b-[3px]  border-b-gray-400')}>Nursing Actions for Progress</label>
          <label onClick={() => { setActiveStep(false); }} className={'ml-4 font-bold cursor-pointer ' + (activeStep ? 'text-gray-400 border-b-[3px] border-b-gray-400' : (theme.txt_color + ' text-white  border-b-[3px] border-b-amber-500'))}>Actions created by Nursing for other departments </label>
        </div>
        {/* <button
          onClick={() => setOpenModal(2)}
          className="col-span-2">Add Test</button> */}

        <button onClick={() => setOpenModal(2)} className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black  hover:bg-green-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
          <AddIcon fontSize='small' />
          <label className=" cursor-pointer">Add Test</label>
        </button>
      </div>
      {openModal == 1 && <ActionItem nursingActionItem={nursingActionItem} setOpenModal={setOpenModal} coupleid={coupleid} />}
      {openModal == 2 && <AddNurseAction setOpenModal={setOpenModal} />}

      {activeStep ?
        <div className={`${theme.thumb} ${theme.track}   w-full flex gap-x-2 scrollbar overflow-y-auto h-[80vh] `}>
          <div className={`${theme.thumb} ${theme.track} w-2/3 bg-white  rounded-md shadow  overflow-y-auto scroll scrollbar-thin height-80`} >
            <div className={theme.navbar + ' p-2 sticky top-0 text-white text-left shadow rounded-t'}><p className=' text-sbase font-medium '>Actions for Progress</p></div>

            {nursingActionData && nursingActionData
              .filter(item => item.CompletionStatus === 'Completed' && item.closeDate == null).map((item) => (
                <div className={item.itemtype + ` p-2 w-full  mb-1 items-center rounded ${theme.tableBg} border shadow border-[${item.NoteColor}] `} key={'item.recordID'}>
                  < div className={` rounded-full shadow`}>

                  </div>
                  <div className={'px-2'}>
                    <button onClick={() => { setOpenModal(1); setNursingActionItem(item) }} className="col-span-2">
                      <p className='text-sm text-gray-700 font-semibold '><span className='text-red-600'> Nursing Action: </span>{item.actionTypeName} by ~ {item.AddedByName ? <i><b>{item.PatientSurname}</b></i> : ' '} on ~ {item.addedDate && <> <i><b>{getCorrectDate(item.addedDate)} </b></i> at <i><b>{formatTime(item.addedDate, true)}</b></i> </>} {item.addedDateOnly ? ' ( ' + formatTimeDifference(item.addedDateOnly) + ' ) ' : ''}</p>
                      <p className='text-sm3 font-medium'>Nursing - {item.actionTypeName}: {item.actionDesc} - for {item.PatientSurname}, {item.PartnerFirstname}</p>
                    </button>
                  </div>
                  <div className={'px-2 flex gap-x-4 rounded-md ' + theme.hoverBg}>
                    <p className=' text-sbase  text-gray-700 flex items-center'>
                      {getEnumValueById(item?.ProcedureTypeId) === "---" ? '' : (
                        <div className={`flex flex-col h-7 w-7 shadow rounded items-center justify-center  `}
                          style={{ backgroundColor: (getCycleTypeColor(getEnumValueById(item?.ProcedureTypeId)?.split(' ')[0]?.trim()) || 'gray') }}>
                          <label className={` text-xss text-center font-semibold  w-6 overflow-hidden  text-${getTextColor(getCycleTypeColor(item?.ProcedureTypeId))}`}>{getEnumValueById(item?.ProcedureTypeId)}</label>
                          <div className="rounded bg-white flex  items-center justify-center h-3 w-5 my-0.5" >{item?.version  && <label className={`  text-smm font-bold   text-gray-600`}>v{item?.version} </label>}</div>
                        </div>
                      )}
                    </p>
                    <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                      <label className={" text-xss font-semibold  "} >Patient: {item.PatientSurname}</label>
                      <p className='text-xss text-gray-600 font-medium'>DOB : {getCorrectDate(item.PatientDOB)}</p>
                    </div>
                    <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                      <label className={" text-xss font-semibold  "} >Partner: {item.PartnerFirstname}</label>
                      <p className='text-xss text-gray-600 font-medium'>DOB : {getCorrectDate(item.PartnerDOB)}</p>
                    </div>
                    <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                      <label className={" text-xss font-semibold "} >{item.couplecode}</label>
                      <p className='text-xss text-gray-600 font-medium'>{item?.clinicianName}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* left */}
          <div className={theme.thumb + ' ' + theme.track + ` w-1/3 bg-white  rounded-md shadow  overflow-y-auto scroll scrollbar-thin height-80`} >
            <div className={theme.navbar + ' p-2  text-gray-900 text-left shadow rounded-t'}><p className='text-white text-sbase font-medium '>Actions Completed Today</p></div>

            {nursingActionData && nursingActionData
              .filter(item => item.CompletionStatus === 'NotCompleted' && new Date(item.addedDate).toDateString() === new Date().toDateString()).map((item) => (
                <div className={'item.NoteColor' + ` p-2 w-full  mb-1 items-center rounded ${theme.tableBg} border  shadow border-[${'item.NoteColor'}] `} key={'item.recordID'}>
                  <div className={'px-2'}>
                    <p className='text-sm text-gray-700 font-semibold '><span className='text-green-600'>Completed -</span> Nursing - {item.actionTypeName}: {item.PatientSurname}, {item.PartnerFirstname}</p>
                    <p className='text-sm3 font-medium'>Nursing - {item.actionTypeName}: {item.PartnerSurname}, {item.PartnerFirstname}</p>
                  </div>
                  <div className={'px-2 flex gap-x-4 rounded-md ' + theme.hoverBg}>
                    <p className=' text-sbase  text-gray-700 flex items-center'>
                    {getEnumValueById(item?.ProcedureTypeId) === "---" ? '' : (
                        <div className={`flex flex-col h-7 w-7 shadow rounded items-center justify-center  `}
                          style={{ backgroundColor: (getCycleTypeColor(getEnumValueById(item?.ProcedureTypeId)?.split(' ')[0]?.trim()) || 'gray') }}>
                          <label className={` text-xss text-center font-semibold  w-6 overflow-hidden  text-${getTextColor(getCycleTypeColor(item?.ProcedureTypeId))}`}>ivf{getEnumValueById(item?.ProcedureTypeId)}</label>
                          <div className="rounded bg-white flex  items-center justify-center h-3 w-5 my-0.5" >{item?.TPVersion  && <label className={`  text-smm font-bold   text-gray-600`}>v{'1'} </label>}</div>
                        </div>
                      )}
                    </p>
                    <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                      <label className={" text-xss font-semibold  "} >Patient: {item.PatientFirstname}</label>
                      <p className='text-xss text-gray-600 font-medium'>DOB :  {getCorrectDate(item.PatientDOB)}</p>
                    </div>

                    <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                      <label className={" text-xss font-semibold "} >{item.couplecode}</label>
                      <p className='text-xss text-gray-600 font-medium'>{item.AddedByName}</p>

                    </div>
                  </div>
                  <p className='text-sm text-gray-700 font-semibold text-end'> ~requested by ~ {item.PatientFirstname ? <i><b>{item.PatientFirstname}</b></i> : ' '} on ~ {item.addedDate && <> <i><b>{getCorrectDate(item.addedDate)} </b></i> at <i><b>{formatTime(item.addedDate, true)}</b></i> </>} {item.addedDateOnly ? ' ( ' + formatTimeDifference(item.addedDateOnly) + ' ) ' : ''}</p>

                </div>
              ))}
          </div>
        </div>
        :
        <div className='w-full flex gap-x-2'>
          <div className={`${theme.thumb} ${theme.track} w-2/3 bg-white  rounded-md shadow   overflow-y-auto scroll scrollbar-thin height-80`} >
            <div className={theme.navbar + ' p-2  text-white text-left shadow rounded-t'}><p className=' text-sbase font-medium '>Actions for Progress created by Nursing for other departments</p></div>
            {nursingActionData && nursingActionData
              .filter(item => item.CompletionStatus === 'Completed' && item.closeDate == null).map((item, index) => (
                <div className={'item.NoteColor' + ` p-2 w-full  mb-1 items-center rounded ${theme.tableBg} border shadow border-[${'item.NoteColor'}] `} key={'item.recordID'}>
                  < div className={` rounded-full shadow`}>
                  </div>
                  <div className={'px-2'}>
                    <p className='text-sm text-gray-700 font-semibold '>{index}.    {item.addedDate && <> <i><b>{getCorrectDate(item.addedDate)} </b></i> at <i><b>{formatTime(item.addedDate, true)}</b></i> </>} {item.addedDateOnly ? ' ( ' + formatTimeDifference(item.addedDateOnly) + ' ) ' : ''}
                      {item.couplecode} {item.actionDesc}
                    </p>
                  </div>
                </div>
              ))}
          </div>
          {/* left */}
          <div className={theme.thumb + ' ' + theme.track + ` w-1/3 bg-white  rounded-md shadow  overflow-y-auto scroll scrollbar-thin height-80`} >
            <div className={theme.navbar + ' p-2  text-gray-900 text-left shadow rounded-t'}><p className='text-white text-sbase font-medium '>Actions Completed Today</p></div>

            {nursingActionData && nursingActionData
              .filter(item => item.CompletionStatus === 'NotCompleted' && new Date(item.addedDate).toDateString() === new Date().toDateString()).map((item) => (
                <div className={'item.NoteColor' + ` p-2 w-full  mb-1 items-center rounded ${theme.tableBg} border  shadow border-[${'item.NoteColor'}] `} key={'item.recordID'}>
                  <div className={'px-2'}>
                    <p className='text-sm text-gray-700 font-semibold '><span className='text-green-600'>Completed -</span> Nursing - {item.actionTypeName}: {item.PatientSurname}, {item.PartnerFirstname}</p>
                    <p className='text-sm3 font-medium'>Nursing - {item.actionTypeName}: {item.PartnerSurname}, {item.PartnerFirstname}</p>
                  </div>
                  <div className={'px-2 flex gap-x-4 rounded-md ' + theme.hoverBg}>
                    <p className=' text-sbase  text-gray-700 flex items-center'>
                    {getEnumValueById(item?.ProcedureTypeId) === "---" ? '' : (
                        <div className={`flex flex-col h-7 w-7 shadow rounded items-center justify-center  `}
                          style={{ backgroundColor: (getCycleTypeColor(getEnumValueById(item?.ProcedureTypeId)?.split(' ')[0]?.trim()) || 'gray') }}>
                          <label className={` text-xss text-center font-semibold  w-6 overflow-hidden  text-${getTextColor(getCycleTypeColor(item?.ProcedureTypeId))}`}>ivf{getEnumValueById(item?.ProcedureTypeId)}</label>
                          <div className="rounded bg-white flex  items-center justify-center h-3 w-5 my-0.5" >{item?.TPVersion  && <label className={`  text-smm font-bold   text-gray-600`}>v{'1'} </label>}</div>
                        </div>
                      )}
                    </p>
                    <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                      <label className={" text-xss font-semibold  "} >Patient: {item.PatientFirstname}</label>
                      <p className='text-xss text-gray-600 font-medium'>DOB :  {getCorrectDate(item.PatientDOB)}</p>
                    </div>

                    <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                      <label className={" text-xss font-semibold "} >{item.couplecode}</label>
                      <p className='text-xss text-gray-600 font-medium'>{item.AddedByName}</p>

                    </div>
                  </div>
                  <p className='text-sm text-gray-700 font-semibold text-end'> ~requested by ~ {item.PatientFirstname ? <i><b>{item.PatientFirstname}</b></i> : ' '} on ~ {item.addedDate && <> <i><b>{getCorrectDate(item.addedDate)} </b></i> at <i><b>{formatTime(item.addedDate, true)}</b></i> </>} {item.addedDateOnly ? ' ( ' + formatTimeDifference(item.addedDateOnly) + ' ) ' : ''}</p>

                </div>
              ))}
          </div>
        </div>
      }
    </div>
  )}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { ApiCall_GET }

export default connect(mapStateToProps, mapDispatchToProps)(NurseAction)
