import React, { useEffect, useState } from 'react'
import APIURLS from "../../../../Common/APIURLS"
import { ApiCall_POST, ApiCall_GET } from '../../../../Redux/Generic/action';
import { connect } from 'react-redux';
import { ActionTypes, formatTime, formatTimeDifference, getCorrectDate, getCycleTypeColor, getEnumValueById, getTextColor, replaceEmptyObjectsWithNull } from '../../../../common';
import { useAppContext } from '../../../../AppContextProvider ';
import TreatmentPlanSummary from '../NurseMagnet/StimPlan/TreatmentPlanSummary';
import TreatmentPlan from '../../Magnet/TreatmentPlan/TreatmentPlan';
import Scans from '../../../Pages/Magnet/Scans/Scans';
import BookingForm from '../../Magnet/BookingForm/BookingForm';

function ActionItem({ ApiCall_GET, nursingActionItem, setOpenModal, coupleid }) {

    const theme = useAppContext().themeDetails;
    const [actionTypes, setActionTypes] = useState([]);
    const [showActions, setshowActions] = useState(true);

    useEffect(() => {

    }, []);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (

        <div className="fixed z-50 inset-0 overflow-y-auto " >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div onClick={() => setOpenModal(0)}

                    style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                {/* <Draggable> */}
                <div className={`sm:my-8  ${showActions == true ? 'sm:max-w-lg ' : 'md:w-4/5'} sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >

                    <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                        <svg className={' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
                            onClick={() => setOpenModal(0)}
                            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                        </svg>
                        {showActions ? <>
                            <h3 className={theme.txt_color + " text-xl text-center mb-6 leading-6 font-bold text-gray-900"} id="modal-headline" >Action Item Options</h3>
                            <div className=' my-3 w-full items-center bg-gray-100 p-3 my-2  rounded-lg' onMouseDown={(e) => e.stopPropagation()}>
                                <div id="alert-4" className="flex my-1 items-center self-end shadow p-1 px-2 mr-0 justify-center border rounded-lg bg-green-300 " role="alert">
                                    <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#11381f" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <span className="sr-only">Info</span>
                                    <div className="ms-3 text-sm font-medium">
                                        <i> ~ You can only acknowledge 'Nursing' actions
                                            because you are an authorised Clinic Supervisor ~</i>
                                    </div>
                                </div>
                                <div className={nursingActionItem?.itemtype + ` p-2 w-full  mb-1 items-center rounded ${theme.tableBg} border shadow border-[${nursingActionItem.NoteColor}] `}>
                                    < div className={` rounded-full shadow`}>

                                    </div>
                                    <div className={'px-2'}>
                                        <p className='text-sm text-gray-700 font-semibold '><span className='text-red-600'> Nursing Action: </span>{nursingActionItem.actionTypeName} by ~ {nursingActionItem.AddedByName ? <i><b>{nursingActionItem.PatientSurname}</b></i> : ' '} on ~ {nursingActionItem.addedDate && <> <i><b>{getCorrectDate(nursingActionItem.addedDate)} </b></i> at <i><b>{formatTime(nursingActionItem.addedDate, true)}</b></i> </>} {nursingActionItem.addedDateOnly ? ' ( ' + formatTimeDifference(nursingActionItem.addedDateOnly) + ' ) ' : ''}</p>
                                        <p className='text-sm3 font-medium'>Nursing - {nursingActionItem.actionTypeName}: {nursingActionItem.actionDesc} - for {nursingActionItem.PatientSurname}, {nursingActionItem.PartnerFirstname}</p>
                                    </div>
                                    <div className={'px-2 flex gap-x-4 rounded-md ' + theme.hoverBg}>
                                        <p className=' text-sbase  text-gray-700 flex items-center'>
                                            {getEnumValueById('artDetails[0]?.ProcedureTypeId') === "---" ? '' : (
                                                <div className={`flex flex-col h-7 w-7 shadow rounded items-center justify-center  `}
                                                    style={{ backgroundColor: (getCycleTypeColor(getEnumValueById('artDetails[0]?.ProcedureTypeId')?.split(' ')[0]?.trim()) || 'gray') }}>
                                                    <label className={` text-xss text-center font-semibold  w-6 overflow-hidden  text-${getTextColor(getCycleTypeColor('artDetails[0]?.ProcedureTypeId'))}`}>{getEnumValueById('artDetails[0]?.ProcedureTypeId')}</label>
                                                    <div className="rounded bg-white flex  items-center justify-center h-3 w-5 my-0.5" >{'artDetails[0]?.TPVersion ' && <label className={`  text-smm font-bold   text-gray-600`}>v{'2'} </label>}</div>
                                                </div>
                                            )}
                                        </p>
                                        <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                                            <label className={" text-xss font-semibold  "} >Patient: {nursingActionItem.PatientSurname}</label>
                                            <p className='text-xss text-gray-600 font-medium'>DOB : {getCorrectDate(nursingActionItem.PatientDOB)}</p>
                                        </div>
                                        <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                                            <label className={" text-xss font-semibold  "} >Partner: {nursingActionItem.PartnerFirstname}</label>
                                            <p className='text-xss text-gray-600 font-medium'>DOB : {getCorrectDate(nursingActionItem.PartnerDOB)}</p>
                                        </div>
                                        <div className={'text-left  cursor-pointer items-center gap-x-3 mr-2 p-1 rounded '}>
                                            <label className={" text-xss font-semibold "} >{nursingActionItem.couplecode}</label>
                                            <p className='text-xss text-gray-600 font-medium'>{nursingActionItem?.clinicianName}</p>
                                        </div>
                                    </div>
                                </div>

                                <button onClick={() => setshowActions(false)}
                                    className="bottom-4  w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700"
                                    type="submit"
                                >
                                    Enter the ART Cycle Outcome {coupleid} --{nursingActionItem.artid}
                                </button>
                                <button
                                    className="bottom-2 w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700"
                                    type="submit"
                                >
                                    Cancel the request / false alarm
                                </button>
                            </div>
                        </> :
                            < div >
                                {(() => {
                                    switch (nursingActionItem.actionTypeId) {
                                        case ActionTypes.GENERAL_ACTION:
                                            return <p>Details for General Action</p>;
                                        case ActionTypes.TREATMENT_PLAN:
                                            return <TreatmentPlan artid={nursingActionItem.artid}
                                                coupleid={coupleid}
                                            // IsCalledByView={isCalledForView}
                                            // patientDetails={patientState.couple_File_People}

                                            // isEmptyART={isEmptyART} 
                                            />
                                        case ActionTypes.NEW_PATIENT_COUPLE:
                                            return <p>Details for New Patient or Couple</p>;
                                        case ActionTypes.NEW_ART_CYCLE:
                                            return <p>Details for New ART Cycle</p>;
                                        case ActionTypes.ADMIN_ACTION:
                                            return <p>Details for Admin Action</p>;
                                        case ActionTypes.NURSING_ACTION:
                                            return <p>Details for Nursing Action</p>;
                                        case ActionTypes.LAB_ACTION:
                                            return <p>Details for Lab Action</p>;
                                        case ActionTypes.ACTION_FOR_CLINICIAN:
                                            return <p>Details for Action for Clinician</p>;
                                        case ActionTypes.BOOKING_FORM:
                                            return <p>Details for Booking Form</p>;
                                        case ActionTypes.SCAN_RESULT:
                                            // return <p>Details for Scan Result</p>;
                                        return <TreatmentPlan artid={nursingActionItem.artid} coupleid={coupleid} callFromAction={true} setOpenModal={setOpenModal} />;
                                        // return <Scans artid={nursingActionItem.artid} coupleid={coupleid} callFromAction={true}  setOpenModal={setOpenModal} />;
                                        // return <BookingForm artid={nursingActionItem.artid} coupleid={coupleid} callFromAction={true}  setOpenModal={setOpenModal}  />;

                                        case ActionTypes.ACTION_FOR_CLINIC_MANAGER:
                                            return <p>Details for Action for Clinic Manager</p>;
                                        case ActionTypes.TRANSPORT_FROZEN_STOCK:
                                            return <p>Details for Transport Frozen Stock</p>;
                                        case ActionTypes.BLOOD_WORKS:
                                            return <p>Details for Blood Works</p>;
                                        case ActionTypes.FROZEN_STOCK_RETURN:
                                            return <p>Details for Frozen Stock Return</p>;
                                        case ActionTypes.RESERVATION_EXTENSION:
                                            return <p>Details for Reservation Extension</p>;
                                        case ActionTypes.RESERVATION_CANCELLED:
                                            return <p>Details for Reservation Cancelled</p>;
                                        case ActionTypes.DONOR_STOCK_RESERVATION:
                                            return <p>Details for Donor Stock Reservation</p>;
                                        case ActionTypes.RESERVATION_EXPIRED:
                                            return <p>Details for Reservation Expired</p>;
                                        case ActionTypes.CLINICIAN_REQUESTED_CANCEL_CYCLE:
                                            return <p>Details for Clinician Requested Cancel Cycle</p>;
                                        case ActionTypes.ART_CYCLE_CANCELLED:
                                            return <p>Details for ART Cycle Cancelled</p>;
                                        case ActionTypes.PREGNANCY_SCAN_RESULT:
                                            return <p>Details for Pregnancy Scan Result</p>;
                                        case ActionTypes.PREGNANCY_SCAN:
                                            return <p>Details for Pregnancy Scan</p>;
                                        case ActionTypes.KNOWN_DONOR_ANDROLOGY_REQUEST:
                                            return <p>Details for Known Donor Andrology Request</p>;
                                        case ActionTypes.TREATMENT_PLAN_FINAL:
                                            return <p>Details for Final Treatment Plan</p>;
                                        default:
                                            return <p>Unknown Action Type</p>;
                                    }
                                })()}
                            </div>}
                    </div>
                </div>
            </div>
        </div >
    )
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = { ApiCall_POST, ApiCall_GET };

export default connect(mapStateToProps, mapDispatchToProps)(ActionItem);
