import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppContext } from '../../../../../AppContextProvider ';
import { ApiCall_GET, ApiCall_POST } from '../../../../../Redux/Generic/action';
import { getCorrectDateForInput } from '../../../../../common';
import Swal from 'sweetalert2';
import PinModal from '../../../../Components/PinModal';

export const Screening = ({ setIsModalOpen, cplTestData, ApiCall_GET, ApiCall_POST, onSendData }) => {
    const [screenTestData, setScreenTestData] = useState([]);
    const [formData, setFormData] = useState([]);
    const theme = useAppContext().themeDetails;
    const [date, setDate] = useState("");
    const isSelectedDataNull = cplTestData.selectedData == null;
    const [isValid, setIsValid] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const disabledItems = ["HIV", "HepB", "HepC", "Syphillis", "BG Antibody", "HTLV 1+2", "CMV IgG", "CMV IgM", "Gonorrhoea", "Chlamydia", "Varicella"];

    useEffect(() => {
        getAllScreenTestMaster();
    }, []);

    const getAllScreenTestMaster = () => {
        ApiCall_GET('Screening/GetAllScreenTestMaster/')
            .then((payload) => {
                if (cplTestData.selectedData == null) {
                    setScreenTestData(payload);
                    setFormData(payload.map((e) => ({
                        screentestmasterid: e.screentestmasterid,
                        testname: e.testname,
                        selectedOption: "Not Tested",
                        inputValue: ''
                    })));
                } else {
                    let filteredPayload = payload.filter((e) => e.screentestmasterid === cplTestData.selectedData.screentestmasterid);
                    setScreenTestData(filteredPayload);
                    setFormData(filteredPayload.map(() => ({
                        selectedOption: cplTestData.selectedData.result,
                        inputValue: cplTestData.selectedData.level
                    })));
                }
            });
    };

    const handleCheckboxChange = (index, selectedOption) => {
        setFormData(prevData => {
            const updatedData = [...prevData];
            updatedData[index].selectedOption = selectedOption;
            return updatedData;
        });
    };

    const handleInputChange = (index, value) => {
        setFormData(prevData => {
            const updatedData = [...prevData];
            updatedData[index].inputValue = value;
            return updatedData;
        });
    };

    const handleDateChange = (event) => {
        setDate(event.target.value);
        setIsValid(true);
    };

    const  validateForm = ()=>{
        let isFormValid = true; 
        if (!date) {
            setIsValid(false);
            isFormValid = false;
        }
        const filteredData = formData.filter((item) => item.selectedOption !== "Not Tested");
        for (const item of filteredData) {
            if (disabledItems.includes(item.testname)) {
                continue;
            }

            if (
                (item.selectedOption === 'Positive' || item.selectedOption === 'Negative') && !item.inputValue 
            ) {
                isFormValid = false;
                break;
            }
        }
        if (filteredData.length === 0 && date){
            Swal.fire({
                icon: 'error',
                title: 'Please select at least one option',
                showConfirmButton: false,
                timer: 3000
            })
            isFormValid = false;
        }
        if (!isFormValid) {
            setIsValid(false);
            return; 
        }
        setShowModal(true)
    }

    const handlesubmit = async (event) => {
        const filteredData = formData.filter((item) => item.selectedOption !== "Not Tested");
        const dataToSubmit = filteredData.map((item, index) => ({
            ScreentestMasterId: isSelectedDataNull ? item.screentestmasterid : cplTestData.selectedData.screentestmasterid,
            Result: item.selectedOption,
            Level: item.inputValue,
            PersonId: cplTestData.personId,
            Date: isSelectedDataNull ? date : cplTestData.selectedData.date,
            CoupleId: cplTestData.coupleId,
            ScreenTestId: isSelectedDataNull ? 0 : cplTestData.selectedData.screentestid
        }));
        ApiCall_POST('Screening/Save', dataToSubmit, true).then(function (payload) {
            setIsModalOpen(false);
            onSendData(true);
        });
    };

    return (

        <div className="fixed z-50 inset-0 overflow-y-auto " >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div onClick={() => { setIsModalOpen(false) }} style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                {/* <Draggable> */}
                <div className={`sm:my-8   md:w-2/3 sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                        <svg className={' absolute top-3 right-3 cursor-pointer shadow  font-semibold  '}
                            onClick={() => { setIsModalOpen(false) }}
                            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                        </svg>
                        <div className='rounded mb-0.5 w-full px-2 pt-4 flex items-center justify-between'>
                            <h3 className={theme.txt_color + " text-xl text-center leading-6 font-bold text-gray-900"} id="modal-headline" >Patient Portal Access  </h3>
                            <div className='rounded  w-1/3 px-2 flex items-center justify-end gap-x-2'>
                                <label htmlFor="Negative" className={" text-sm px-2 "} >Test Date</label>
                                <input
                                    type="date"
                                    id="date"
                                    value={getCorrectDateForInput(cplTestData?.selectedData?.date || '')}
                                    onChange={handleDateChange}
                                    className={`${theme.cheqColor} border w-32 ${isValid ? 'border-gray-300' : 'border-red-500'} shadow  rounded py-0.5 px-2 text-sm text-gray-700  focus:outline-none`}
                                    required
                                />
                            </div>
                        </div>
                        <div className=' my-3 w-full items-center bg-gray-100  rounded-lg' onMouseDown={(e) => e.stopPropagation()}>
                            <div className={`${theme.thumb} ${theme.track} w-full h-[50vh] flex overflow-y-auto scrollbar scroll scrollbar  scrollbar-thin gap-x-2  bg-white`} >
                                <div className={`  w-full rounded`}>
                                    <div className={` bg-[#f0f5ff] w-full shadow relative  font-normal   `}>
                                        <div className={`bg-[#A9C6FF] sticky top-0  w-full flex justify-between  text-left p-2 rounded-t`}>
                                            <p className='w-1/3 text-sm font-medium'>Screening Test Type</p>
                                            <p className='w-1/3 text-sm font-medium'>Screening Test Results</p>
                                            <p className='w-1/3 text-sm font-medium'>Level</p>
                                        </div>
                                        {screenTestData && screenTestData.length > 0 ? (
                                            <>
                                                {screenTestData.map((item, index) => (
                                                    <div key={index} className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                                        <p className='cursor-pointer text-sbase font-normal w-1/3 text-gray-900'>{item.testname || 'N/A'}</p>
                                                        <div className={`w-1/3 text-left gap-x-4 flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                                            <div className='rounded  px-2 flex items-center'>
                                                                <input
                                                                    type="radio"
                                                                    name={`optionGroup${index}`}
                                                                    checked={formData[index].selectedOption === 'Not Tested'}
                                                                    onChange={() => handleCheckboxChange(index, 'Not Tested')}
                                                                    className={theme.cheqColor + ' border focus:outline-none'}
                                                                />
                                                                <label htmlFor="disability_yes" className="ml-2 text-sm text-gray-800">Not Tested</label>
                                                            </div>
                                                            <div className='rounded mb-0.5  px-2 flex items-center justify-'>
                                                                <input
                                                                    type="radio"
                                                                    name={`optionGroup${index}`}
                                                                    checked={formData[index].selectedOption === 'Positive'}
                                                                    onChange={() => handleCheckboxChange(index, 'Positive')}
                                                                    className={theme.cheqColor + ' border focus:outline-none'}
                                                                />
                                                                <label htmlFor="Positive" className={" text-sm px-2 "} >Positive</label>
                                                            </div>
                                                            <div className='rounded mb-0.5  px-2 flex items-center justify-'>
                                                                <input
                                                                    type="radio"
                                                                    name={`optionGroup${index}`}
                                                                    checked={formData[index].selectedOption === 'Negative'}
                                                                    onChange={() => handleCheckboxChange(index, 'Negative')}
                                                                    className={theme.cheqColor + ' border focus:outline-none'}
                                                                />
                                                                <label htmlFor="Negative" className={" text-sm px-2 "} >Negative</label>
                                                            </div>
                                                        </div>

                                                        <div className={`rounded mb-0.5 w-1/3 px-2 flex items-center justify-start`}>
                                                            <label htmlFor="Level" className="text-sm px-2">Level</label>
                                                            <input
                                                                type="text"
                                                                value={formData[index].inputValue}
                                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                                                className={`${(disabledItems.includes(item.testname) || !(formData[index].selectedOption === 'Positive' || formData[index].selectedOption === 'Negative') || formData[index].inputValue)
                                                                    ? theme.cheqColor // Use the default theme color if disabled, not required, or if input has value
                                                                    : 'border-red-500' // Use red border color if not disabled, option is Positive or Negative, and input is empty
                                                                    } border focus:outline-none focus:bg-white border font-normal  w-1/2 font-normal rounded py-0.5 px-2 text-sm text-gray-900  focus:outline-none transition delay-150 duration-500`}
                                                                disabled={disabledItems.includes(item.testname)}  // Disable input based on the test name
                                                                required={!disabledItems.includes(item.testname) && (formData[index].selectedOption === 'Positive' || formData[index].selectedOption === 'Negative')}
                                                            />

                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <div id="alert-4" className="flex justify-center w-full self-end shadow p-2  m-2 mr-0 text-[#664d03] border border-red-300 rounded-lg bg-red-100  dark:text-yellow-300" role="alert">
                                                <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#9b1c1c" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                                </svg>
                                                <span className="sr-only">Info</span>
                                                <div className="ms-3 text-sm font-semibold text-red-800">
                                                    <i><b> ~ No data available ~</b></i>
                                                </div>
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='xl:w-full bg-white  py-2  rounded flex justify-end items-center  px-6  mt-0.5 bottom-0 right-[24px]' >
                            <button onClick={() => { setIsModalOpen(false) }} className={"bg-white bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"}  > Cancel  </button>
                            <button onClick={validateForm} className={"bg-white bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"}>Submit</ button>
                        </div >
                    </div>
                    {showModal && <PinModal title="Saving Couple Details" setShowModal={setShowModal} successFunction={(e) => handlesubmit(e)} />}
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({})


export default connect(mapStateToProps, { ApiCall_GET, ApiCall_POST })(Screening);
