import React from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../../AppContextProvider ';
import { getCorrectDateForInput } from '../../../../common';

export const PretreatmentWorkup = ({ couple, coupleData }, props) => {

    const theme = useAppContext().themeDetails;
    return (
        <div className={`${theme.thumb} ${theme.track}  ${theme.thin} relative  w-full overflow-y-scroll scrollbar scrollbar-thin h-screen pb-32 bg-gray-100  `}>
            <div className='w-full py-2 shadow  bg-white border rounded-md  mb-2'>
                <div className='flex w-full'>
                    <div className={`${theme.hoverBg} rounded text-left cursor-pointer mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/3 `}>
                        <p className='w-1/2  text-sbase font-medium   text-gray-700'>Initial GP/Nurse Interview Date </p>
                        <p className={'text-sm text-gray-500  '}>{couple?.patientFullName}00</p>
                    </div>
                    <div className={`${theme.hoverBg} rounded text-left mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-2/3 `}>
                        <p className='cursor-pointer text-sbase w-28 font-medium   text-gray-700'>GP Referral
                        </p>
                        <p className={'text-sm text-gray-500  '}>{couple?.partnerFullName}11</p>
                    </div>
                </div>
                <div className='flex w-full'>
                    <div className={`${theme.hoverBg} rounded text-left  mx-2 flex items-center  py-1    w-1/3`}>
                        <p className=' text-sm font-medium  w-1/2 text-gray-700'>Fertility Specialist Appointment</p>
                        <p className={'text-sm text-gray-500 '}> {couple?.clinicianName}33</p>
                    </div>
                    <div className=' text-left  mx-2 flex  items-center   w-2/3 '>
                        <div className={`${theme.hoverBg} rounded text-left py-1  flex  w-1/2 `}>
                            <p className='cursor-pointer text-sbase w-28 font-medium   text-gray-700'>Patient BMI</p>
                            <p className={'text-sm text-gray-500  '}>
                                {couple?.Patientbmi}44
                            </p>
                        </div>
                        <div className={`${theme.hoverBg} rounded text-left py-1  items-center flex  w-1/2 `}>
                            <p className='cursor-pointer text-sbase w-28 font-medium   text-gray-700'>Partner BMI</p>
                            <p className={'text-sm text-gray-500  '}>
                                {couple?.Partnerbmi}44
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={` w-full rounded  bg-white`}>
                <div className={` w-full shadow  mb-2 font-normal   `}>
                    <div className={` ${theme.tHedUpdte} flex justify-between  text-white  text-left p-2 rounded-t`}>
                        <p className='text-sm font-medium'>Work Up Summar1y</p>
                    </div>

                    <div className='w-full items-center flex  font-normal py-1 '>
                        <div className='w-1/3 items-center  font-normal  '>
                            <div className={`w-full text-left flex items-center py-1 px-2 ${theme.hoverBg}`}>
                                <p className='w-1/3  text-sm font-normal  text-gray-700'>Status</p>
                                <input
                                    type="text"
                                    name='PatientLastName'
                                    // onChange={(e) => handleInputChange(e)}
                                    className={`bg-gray-100 w-2/3 border border-transparent focus:bg-white rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`} />
                                {/* <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientLastName}</p> */}
                            </div>
                            <div className={`w-full text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                <p className="w-1/3 text-sm font-normal text-gray-700">SA</p>
                                <div className="w-2/3 flex items-center">
                                    <input type="text" name="PatientDOB"
                                        // value={getCorrectDateForInput(coupleData.PatientDOB) || ''}
                                        // onChange={(e) => handleInputChange(e)}
                                        className={` bg-gray-100 w-2/3 border border-transparent focus:bg-white rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                                    />
                                    <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-0 px-3 border border-green-500 hover:border-transparent rounded"
                                    // onClick={() => { setShowModal(true) }}
                                    >Save</button>
                                </div>
                            </div>

                        </div>
                        <div className='w-1/3 items-center  font-normal  '>
                            <div className={`w-full text-left flex items-center py-1 px-2 ${theme.hoverBg}`}>
                                <p className='w-1/3  text-sm font-normal  text-gray-700'>Next Follow Up Date</p>
                                <div className="w-2/3 flex items-center">
                                    <input type="text" name="PatientDOB"
                                        // value={getCorrectDateForInput(coupleData.PatientDOB) || ''}
                                        // onChange={(e) => handleInputChange(e)}
                                        className={`bg-gray-100 w-2/3 border border-transparent focus:bg-white rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                                    />
                                    <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-0 px-3 border border-green-500 hover:border-transparent rounded"
                                    // onClick={() => { setShowModal(true) }}
                                    >Save</button>
                                </div>
                            </div>
                            <div className={`w-full text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                <p className="w-1/3 text-sm font-normal text-gray-700">Pelvic US</p>
                                <div className="w-2/3 flex items-center">
                                    <input type="text" name="PatientDOB"
                                        // value={getCorrectDateForInput(coupleData.PatientDOB) || ''}
                                        // onChange={(e) => handleInputChange(e)}
                                        className={`bg-gray-100  w-2/3 border border-transparent focus:bg-white rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                                    />
                                    <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-0 px-3 border border-green-500 hover:border-transparent rounded"
                                    // onClick={() => { setShowModal(true) }}
                                    >Save</button>
                                </div>
                            </div>

                        </div>
                        <div className='w-1/3 items-center  font-normal  '>
                            <div className={`w-full text-left flex items-center py-1 px-2 ${theme.hoverBg}`}>
                                <p className='w-1/3  text-sm font-normal  text-gray-700'>Aetiology Completed</p>
                                <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-0 px-3 border border-green-500 hover:border-transparent rounded"
                                // onClick={() => { setShowModal(true) }}
                                >  Complete</button> </div>
                            <div className={`w-full text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                <p className="w-1/3 text-sm font-normal text-gray-700">Counselling</p>
                                <div className="w-2/3 flex items-center">
                                    <input type="text" name="PatientDOB"
                                        // value={getCorrectDateForInput(coupleData.PatientDOB) || ''}
                                        // onChange={(e) => handleInputChange(e)}
                                        className={`bg-gray-100 w-2/3 border border-transparent focus:bg-white rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                                    />
                                    <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-0 px-3 border border-green-500 hover:border-transparent rounded"
                                    // onClick={() => { setShowModal(true) }}
                                    >Save</button>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div className='w-full flex overflow-hidden gap-x-2  bg-white' >
                {/* Patient Information */}
                <div className={`  w-1/2 rounded`}>
                    <div className={`${'coupleData?.PatientGender' !== 'F' ? 'bg-[#ffc3c340]' : "bg-[#f0f5ff]"} w-full shadow  mb-2 font-normal   `}>
                        <div className={`${'coupleData?.PatientGender' !== 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}>
                            <p className='text-sm font-medium'>Patient Information</p>
                            {'coupleData.PatientGender' !== 'F' ?
                                <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                </svg>
                                :
                                <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                        </div>
                        <div className='w-full items-center flex  font-normal  '>
                            <div className='w-2/3 items-center  font-normal  '>
                                <div className={`w-full text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/3  text-sm font-normal  text-gray-700'>Lab</p>
                                    <input
                                        type="text"
                                        name='PatientLastName'

                                        // onChange={(e) => handleInputChange(e)}
                                        className={`${'coupleData?.PatientGender' !== 'F' ? 'bg-[#f3dfdf]' : 'bg-[#d6e4ff]'} w-2/3 border border-transparent focus:bg-white rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                                    // className={`${coupleData.PatientGender !== 'F' ? 'bg-[#f3dfdf] ' : "bg-[#d6e4ff] "} border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}
                                    />
                                    {/* <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientLastName}</p> */}
                                </div>
                                <div className={`w-full text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className="w-1/3 text-sm font-normal text-gray-700">Date Collected</p>
                                    <input
                                        type="text"
                                        name="PatientDOB"
                                        // value={getCorrectDateForInput(coupleData.PatientDOB) || ''}
                                        // onChange={(e) => handleInputChange(e)}
                                        className={`${'coupleData?.PatientGender' !== 'F' ? 'bg-[#f3dfdf]' : 'bg-[#d6e4ff]'} w-2/3 border border-transparent focus:bg-white rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                                    />
                                </div>

                            </div>
                            <div className={`w-1/3 text-left flex items-center justify-end  py-0.5 px-2 `}>
                                <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-0.5 px-4 border border-green-500 hover:border-transparent rounded"
                                // onClick={() => { setShowModal(true) }}

                                >Save</button>
                            </div>
                        </div>
                        {[{ label: 'HIV', name: '' },
                        { label: 'HepB', name: '' },
                        { label: 'HepC', name: '' },
                        { label: 'Rubella:', name: '' },
                        { label: 'Blood Group', name: '' },
                        { label: 'BG Antibody', name: '' },
                        { label: 'Syphillis', name: '' },
                        { label: 'BG Antibody', name: '' },
                        { label: 'AMH', name: '' },
                        { label: 'HTLV 1+2', name: '' },
                        { label: 'CMV IgG', name: '' }
                        ].map((item, index) => (<div className={`w-full items-center flex shadow my-1 font-normal ${theme.hoverBg} rounded rounded py-1 px-2`}>
                            <div className={`w-full text-left flex items-center py-0.5 px-2 `}>
                                <p className='w-1/3  text-sm font-normal  text-gray-700'>{item.label}</p>
                            </div>

                            {true ? <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-0.5 px-3 border border-green-500 hover:border-transparent rounded"
                            // onClick={() => { setShowModal(true) }}
                            >  Complete</button> :
                                <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.4742 13.6409C13.4872 10.5781 16.3962 8.50041 20.0502 5.55826C21.1442 4.67761 21.3132 3.13386 20.4552 2.04041C19.5432 0.877606 17.8102 0.644856 16.6382 1.56921C13.2912 4.20641 10.6872 6.74671 8.21724 9.45991C8.08724 9.60241 8.02224 9.67366 7.95524 9.71356C7.87245 9.76378 7.77639 9.7907 7.67817 9.7912C7.57995 9.79171 7.48358 9.76578 7.40023 9.71641C7.33223 9.67746 7.26724 9.60716 7.13724 9.46751L5.82124 8.05201C5.54201 7.75057 5.19336 7.51438 4.80239 7.36182C4.41142 7.20925 3.98868 7.14442 3.56704 7.17236C3.1454 7.20031 2.73623 7.32027 2.37134 7.52292C2.00646 7.72558 1.6957 8.00546 1.46324 8.34081C1.12212 8.83056 0.961876 9.41408 1.00767 9.99973C1.05346 10.5854 1.30269 11.14 1.71624 11.5765L3.78024 13.752C5.32324 15.3784 6.09524 16.1926 7.02224 16.2002C7.94824 16.2078 8.79024 15.3528 10.4742 13.6409Z" stroke="#3A7555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>}
                        </div>))}
                    </div>
                </div>
                <div className={`  w-1/2 rounded`}>
                    <div className={`${'coupleData?.PatientGender' === 'F' ? 'bg-[#ffc3c340]' : "bg-[#f0f5ff]"} w-full shadow  mb-2 font-normal   `}>
                        <div className={`${'coupleData?.PatientGender' === 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}>
                            <p className='text-sm font-medium'>Partner Information</p>
                            {'coupleData.PatientGender' === 'F' ?
                                <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                </svg>
                                :
                                <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                        </div>
                        <div className='w-full items-center flex  font-normal  '>
                            <div className='w-2/3 items-center  font-normal  '>
                                <div className={`w-full text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className='w-1/3  text-sm font-normal  text-gray-700'>Lab</p>
                                    <input
                                        type="text"
                                        name='PatientLastName'

                                        // onChange={(e) => handleInputChange(e)}
                                        className={`${'coupleData?.PatientGender' === 'F' ? 'bg-[#f3dfdf]' : 'bg-[#d6e4ff]'} w-2/3 border border-transparent focus:bg-white rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                                    // className={`${coupleData.PatientGender === 'F' ? 'bg-[#f3dfdf] ' : "bg-[#d6e4ff] "} border border-transparent focus:bg-white  w-1/2  rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500 `}
                                    />
                                    {/* <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientLastName}</p> */}
                                </div>
                                <div className={`w-full text-left flex items-center py-0.5 px-2 ${theme.hoverBg}`}>
                                    <p className="w-1/3 text-sm font-normal text-gray-700">Date Collected</p>
                                    <input
                                        type="text"
                                        name="PatientDOB"
                                        // value={getCorrectDateForInput(coupleData.PatientDOB) || ''}
                                        // onChange={(e) => handleInputChange(e)}
                                        className={`${'coupleData?.PatientGender' === 'F' ? 'bg-[#f3dfdf]' : 'bg-[#d6e4ff]'} w-2/3 border border-transparent focus:bg-white rounded py-0.5 px-2 text-sm text-gray-900 focus:border-teal-500 focus:outline-none transition delay-150 duration-500`}
                                    />
                                </div>

                            </div>
                            <div className={`w-1/3 text-left flex items-center justify-end  py-0.5 px-2 `}>
                                <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-0.5 px-4 border border-green-500 hover:border-transparent rounded"
                                // onClick={() => { setShowModal(true) }}

                                >Save</button>
                            </div>
                        </div>
                        {[{ label: 'HIV', name: '' },
                        { label: 'HepB', name: '' },
                        { label: 'HepC', name: '' },
                        { label: 'Rubella:', name: '' },
                        { label: 'Blood Group', name: '' },
                        { label: 'BG Antibody', name: '' },
                        { label: 'Syphillis', name: '' },
                        { label: 'BG Antibody', name: '' },
                        { label: 'AMH', name: '' },
                        { label: 'HTLV 1+2', name: '' },
                        { label: 'CMV IgG', name: '' }
                        ].map((item, index) => (<div className={`w-full items-center flex shadow my-1 font-normal ${theme.hoverBg} rounded rounded py-1 px-2`}>
                            <div className={`w-full text-left flex items-center py-0.5 px-2 `}>
                                <p className='w-1/3  text-sm font-normal  text-gray-700'>{item.label}</p>
                            </div>

                            {true ? <button className="bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-0.5 px-3 border border-green-500 hover:border-transparent rounded"
                            // onClick={() => { setShowModal(true) }}
                            >  Complete</button> :
                                <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.4742 13.6409C13.4872 10.5781 16.3962 8.50041 20.0502 5.55826C21.1442 4.67761 21.3132 3.13386 20.4552 2.04041C19.5432 0.877606 17.8102 0.644856 16.6382 1.56921C13.2912 4.20641 10.6872 6.74671 8.21724 9.45991C8.08724 9.60241 8.02224 9.67366 7.95524 9.71356C7.87245 9.76378 7.77639 9.7907 7.67817 9.7912C7.57995 9.79171 7.48358 9.76578 7.40023 9.71641C7.33223 9.67746 7.26724 9.60716 7.13724 9.46751L5.82124 8.05201C5.54201 7.75057 5.19336 7.51438 4.80239 7.36182C4.41142 7.20925 3.98868 7.14442 3.56704 7.17236C3.1454 7.20031 2.73623 7.32027 2.37134 7.52292C2.00646 7.72558 1.6957 8.00546 1.46324 8.34081C1.12212 8.83056 0.961876 9.41408 1.00767 9.99973C1.05346 10.5854 1.30269 11.14 1.71624 11.5765L3.78024 13.752C5.32324 15.3784 6.09524 16.1926 7.02224 16.2002C7.94824 16.2078 8.79024 15.3528 10.4742 13.6409Z" stroke="#3A7555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>}
                        </div>))}
                    </div>
                </div>

            </div>
            <div className={' text-left   w-full mt-1  border-b-gray-200 flex-wrap   ' + theme.hoverBg}>
                <div className={'bg-[#86B3D1]  text-white text-left p-2 rounded-t'}><p className='text-sbase font-medium'>Notes</p></div>
                <textarea value='' name="comments" rows="5" placeholder=" comments ..."
                    className={`block p-2.5 w-full text-sm px-1 py-1 border border-white rounded accent-green-700 text-gray-900 focus:border-teal-500 focus:outline-none `} />
            </div>
            < div className='xl:w-full bg-white  py-2  rounded flex justify-end items-center  px-6  mt-0.5 bottom-0 right-[24px]' >
                <button className={"bg-white bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"}  > Cancel  </button>
                <button className={"bg-white bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"}> Confirm</ button>
            </div >
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PretreatmentWorkup)