import React from 'react'
import { connect } from 'react-redux'
import { useAppContext } from '../../../AppContextProvider ';

export const PatientPortal = (props, { setShowModal }) => {
    const theme = useAppContext().themeDetails;

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto " >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    onClick={() => { setShowModal(false) }}
                    style={{ backdropFilter: 'blur(7px)' }} className="fixed inset-0 transition-opacity filter" aria-hidden="true" >
                    <div className="absolute inset-0 bg-gray-700 opacity-70  " ></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen filter blur-xl " aria-hidden="true" ></span>
                {/* <Draggable> */}
                <div className={`sm:my-8   md:w-2/3 sm:w-full filter inline-block align-bottom bg-white sm:align-middle  rounded-lg text-left overflow-hidden shadow-xl`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >

                    <div className={`bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative  `}>
                        <svg className={' absolute top-2 right-2 cursor-pointer shadow  font-semibold  '}
                            onClick={() => { setShowModal(false) }}
                            width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="hover:shadow   " d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM12.6 14L9 10.4L5.4 14L4 12.6L7.6 9L4 5.4L5.4 4L9 7.6L12.6 4L14 5.4L10.4 9L14 12.6L12.6 14Z" fill="#ed4545" />
                        </svg>
                        <h3 className={theme.txt_color + " text-xl text-center mb-6 leading-6 font-bold text-gray-900"} id="modal-headline" >Patient Portal Access<span className={`${true ? 'text-[#387153]' : 'text-[#387153]'}`}>{props.title}</span>  </h3>
                        <div className=' my-3 w-full items-center bg-gray-100  rounded-lg' onMouseDown={(e) => e.stopPropagation()}>

                            <div className='w-full flex overflow-hidden gap-x-2  bg-white' >
                                {/* Patient Information */}
                                <div className={`  w-1/2 rounded`}>
                                    <div className={`${'coupleData?.PatientGender' !== 'F' ? 'bg-[#ffc3c340]' : "bg-[#f0f5ff]"} w-full shadow   font-normal   `}>
                                        <div className={`${'coupleData?.PatientGender' !== 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between text-white text-left p-2 rounded-t`}>
                                            <p className='text-sm font-medium'>Patient Information</p>
                                            {'coupleData.PatientGender' !== 'F' ?
                                                <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                                </svg>
                                                :
                                                <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            }
                                        </div>

                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Patient Name</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p>
                                        </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Patient Email</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p>  </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Patient Mobile</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p> </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Patient Username</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p> </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Patient Registered Date</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p> </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Patient Registered Link</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p> </div>

                                    </div>
                                </div>
                                <div className={`  w-1/2 rounded`}>
                                    <div className={`${'coupleData?.PatientGender' === 'F' ? 'bg-[#ffc3c340]' : "bg-[#f0f5ff]"} w-full shadow   font-normal   `}>
                                        <div className={`${'coupleData?.PatientGender' === 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}>
                                            <p className='text-sm font-medium'>Partner Information</p>
                                            {'coupleData.PatientGender' === 'F' ?
                                                <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                                                </svg>
                                                :
                                                <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            }
                                        </div>

                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Partner Name</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p>
                                        </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Partner Email</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p>  </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Partner Mobile</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p> </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Partner Username</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p> </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Partner Registered Date</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p> </div>
                                        <div className={'w-full text-left px-4 p-2 flex border-r border-b-[2px]  border-b-gray-200 ' + theme.hoverBg}>
                                            <p className='cursor-pointer text-sbase font-normal w-1/2 text-gray-900'>Partner Registered Link</p>
                                            <p className='  text-sm text-gray-700 flex items-center'>{'name'}</p> </div>
                                    </div>
                                </div>

                            </div>
                            {true ? <div className={' text-left   w-full mt-2  border-b-gray-200 flex-wrap   '}>
                                <div className={'bg-[#86B3D1]  text-white text-left p-2 rounded-t'}><p className='text-sbase font-medium'>Reason for Disable Couple </p></div>
                                <div className={' text-left  p-2     '}>
                                    <p className='cursor-pointer text-sbase font-normal py-2 text-gray-900'>Reason for disable the couple from patient portal</p>
                                    <textarea value='' name="comments" rows="5" placeholder=" comments ..."
                                        className={`block p-2.5 w-full text-sm px-1 py-1 border border-gray-200 rounded accent-green-700 text-gray-900 focus:border-teal-500 focus:outline-none `} />
                                </div>
                            </div> :
                                <div className={' text-left   w-full mt-2  border-b-gray-200 flex-wrap   '}>
                                    <div className={'bg-[#86B3D1]  text-white text-left p-2 rounded-t'}><p className='text-sbase font-medium'>Check Usernames</p></div>
                                    <div className={' p-2 w-full flex   '}>
                                        <div className={' text-left  p-2 w-1/2    '}>
                                            <p className='cursor-pointer text-sbase font-normal py-2 text-gray-900'>Patient Username *</p>
                                            <textarea value='' name="comments" rows="1" placeholder="  Username ..."
                                                className={`block p-2.5 w-full text-sm px-1 py-1 border border-gray-200 rounded accent-green-700 text-gray-900 focus:border-teal-500 focus:outline-none `} />
                                        </div>
                                        <div className={' text-left  p-2 w-1/2    '}>
                                            <p className='cursor-pointer text-sbase font-normal py-2 text-gray-900'>Patient Username *</p>
                                            <textarea value='' name="comments" rows="1" placeholder="  Username ..."
                                                className={`block p-2.5 w-full text-sm px-1 py-1 border border-gray-200 rounded accent-green-700 text-gray-900 focus:border-teal-500 focus:outline-none `} />
                                        </div>
                                    </div>



                                </div>}
                        </div>
                        <div className='xl:w-full bg-white  py-2  rounded flex justify-end items-center  px-6  mt-0.5 bottom-0 right-[24px]' >
                            <button className={"bg-white bg-transparent mx-2 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1 px-4 border border-red-300 hover:border-transparent rounded"}  > Cancel  </button>
                            <button className={"bg-white bg-transparent ml-2 hover:bg-green-600 text-green-800 font-semibold hover:text-white py-1 px-4 border border-green-500 hover:border-transparent rounded"}> Disable Patient Portal Access</ button>
                        </div >
                    </div>
                </div>
                {/* </Draggable> */}

            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PatientPortal)