import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAppContext } from "../../../../../AppContextProvider ";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCall_GET } from "../../../../../Redux/Generic/action";
import { replaceEmptyObjectsWithNull } from "../../../../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { renderDataOnLoad } from "../../../../../Utils";

export const Outcome = ({ApiCall_GET}) => {
  let navigates = useNavigate();
  let location = useLocation();
  var artid = location?.state?.artid;
  var coupleid = location.state.coupleid;
  const theme = useAppContext().themeDetails??{};

    const [outcomeData, setOutcomeData] = useState([]);
  

  
  useEffect(() => {

     ApiCall_GET('Patient/GetCycleOutcome/' + coupleid, true).then(function (payload) {
        setOutcomeData(replaceEmptyObjectsWithNull(payload))
      
    });
}, [])

      
            return (
              <div className={" bg-white  mb-3 pt-3 p-2 w-full "}>
                <div
                  className={theme.bg_Light + "  p-2 mt-2  flex gap-2 items-center justify-between shadow-md w-full " } >
                  <p>Outcome</p>
                </div>
                <div className={theme.thumb +" " + theme.track + "   overflow-auto h-[70vh] scrollbar pt-2 scrollbar-thin w-full " } style={{ width: "100%" }}>
                  {/* table header */}
                  <div className={"w-full flex py-1 text-white rounded " + theme.navbar}>
                    <div
                      style={{ width: "3%" }}
                      className={"flex item-center justify-center py-1"} >
                      <p className="text-sm font-bold">#</p>
                    </div>
                    <div style={{ width: "10%" }} className={"py-1"}>
                      <p className="text-sm font-bold">Day One</p>
                    </div>
                    <div style={{ width: "15%" }} className={"py-1"}>
                      <p className="text-sm font-bold">Procedure Type</p>
                    </div>
                    <div style={{ width: "10%" }} className={"py-1"}>
                      <p className="text-sm font-bold">Donor Code</p>
                    </div>
                    <div style={{ width: "12%" }} className={"py-1"}>
                      <p className="text-sm font-bold">Clinician</p>
                    </div>
                    <div style={{ width: "15%" }} className={"py-1"}>
                      <p className="text-sm font-bold">Preg Test Status</p>
                    </div>
                    <div style={{ width: "15%" }} className={"py-1"}>
                      <p className="text-sm font-bold">Clinical Preg</p>
                    </div>
                    <div style={{ width: "10%" }} className={"py-1 "}>
                      <p className="text-sm font-bold">Delivery Status</p>
                    </div>
                    <div style={{ width: "10%" }} className={"py-1 "}>
                      <p className="text-sm font-bold">Action</p>
                    </div>
                  </div>
                  {/* display data */}
                  {outcomeData && outcomeData?.map((item, index) => (
                  <div key={index} className={" w-full  flex  mb-0.5 pt-1 bg-[#DBE3DF] rounded shadow border items-center" }>
                    <div
                      style={{ width: "3%" }} className={"flex item-center justify-center py-3"} >
                      <p className="text-sm font-medium text-gray-600">#</p>
                    </div>
                    <div style={{ width: "10%" }} className={"py-1 "}>
                      <p className="text-sm font-medium text-gray-600">{item?.dayofcycle}</p>
                    </div>
                    <div style={{ width: "15%" }} className={"py-1"}>
                      <p className="text-sm font-medium text-gray-600">{item?.cycletype} </p>                      
                    </div >
                    <div style={{ width: "10%" }} className={"py-1"}>
                      <p className="text-sm font-medium text-gray-600">{item?.donorcode}</p>
                    </div>
                    <div style={{ width: "12%" }} className={"py-1"}>
                      <p className="text-sm font-medium text-gray-600">{item?.username}</p>
                    </div>
                   
                    <div style={{ width: "11%" }} className={"py-1"}>
                      <p className="text-sm font-medium text-gray-600">{'BHCG: ' + (item?.multiBhcg > 1 ? " (Multi) " : " ") + (item?.bhcgtestresult || '')  } {(item?.cycletype?.split(" ")[0] === "IUI" || item?.cycletype?.split(" ")[0] === "AIH") && ('Urine: ' + item?.urinetestresult) } </p>
                       </div >
                                  
                    <div style={{ width: "19%" }} className={"py-1"}>
                      <p className="text-sm font-medium text-gray-600">{renderDataOnLoad(item).clinicalPregnancy}{' ART'+item?.artid}</p>
                    </div>
                    <div style={{ width: "10%" }} className={"py-1 "}>
                      <p className="text-sm font-medium text-gray-600">{renderDataOnLoad(item).deliveryStatus}</p>
                    </div>
                    <div style={{ width: "10%" }} className={"py-1 "}>
                      
                    <button
                       className="flex items-center cursor-pointer justify-center text-gray-500 bg-yellow-400 border border-gray-300 focus:outline-none hover:text-black  hover:bg-yellow-600 hover:text-white focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2">
                       <FontAwesomeIcon icon={faEye} />
                          <label className="pl-2 cursor-pointer">View</label>
                     </button>
                    </div>
                  
                  </div>
                    ))}
                </div>
                  
              </div>
            );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {ApiCall_GET};

export default connect(mapStateToProps, mapDispatchToProps)(Outcome);
