import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import logo from '../../../../Assets/images/white.png';
import { getCorrectDate, getCycleTypeColor, getEnumValueById, getTextColor, ohssType, UrineOutputType, getUserInformation } from '../../../../common';
import moment from 'moment'
import { getSession } from '../../../../session';
import { CycleType } from '../../../../common';
import { useAppContext } from '../../../../AppContextProvider ';

export const OhssPrint = ({ artid, artDetails, ohhsDetails, showPreview }) => {
    const user = getUserInformation()
    const theme = useAppContext().themeDetails;
    const [couple, setCouple] = useState(null)
    useEffect(() => {
        setCouple(getSession('patient'))

    }, [])

    return (
        <div id="divPrint" className=" flex min-h-screen w-full  p-2 delay-900 duration-700  ">
            {/* Header */}
            <div className=" w-full   bg-white p-3">
                {showPreview === false && < div className={` ${theme.navbar} ` + ' p-2 flex justify-between  w-full mb-2'} >
                    <div className='flex items-center justify-end '>
                        <img src={logo} className="logoCFC" alt="img" width='160px' />
                        <p className={`dot-${user?.BrandCode?.toUpperCase()} `} style={{ backgroundColor: `#${user?.ChartingColor}` }}></p>

                        <p className=' text-white  text-sm'>{user?.LocationName}</p>
                    </div>
                    <div className='flex justify-end text-white items-center '>
                        <label className='  text-sm'>{moment(new Date()).format('ddd DD-MMM-yyyy')}</label>
                    </div>
                </div>}
                {showPreview === true && <> <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                    Dear Dr David Wilkinson, </p>
                    <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                        Here is the OHSS MONITORING REPORT for the current cycle. </p></>}
                {/* </header>  */}
                <main className=' '>
                    {showPreview === false && <label className={theme.txt_color + 'text-xl font-semibold'}>OHSS  </label>}
                    {/* patient info */}
                    <div className='w-full mt-2 shadow mr-1 mt-3 mb-3'>
                        <p className={theme.tHedUpdte + '  text-sm font-medium text-white text-left p-1  rounded-t'}>Patient / Couple Details</p>

                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex items-center  py-1  border-b-[1px]  border-b-gray-200    w-1/2 '>
                                <p className=' text-sm font-medium   text-gray-700'>Clinician</p>
                                <p className={'pl-2 text-sm text-gray-500  '}> {couple?.clinicianName}</p>

                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className=' text-sm font-medium w-24  text-gray-700'>Couple Code</p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {couple?.coupleCode}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>ART </b>
                                        {artid}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2  '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Patient </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500 flex items-center gap-1 '}>
                                        {couple?.patientFullName}
                                        {couple?.patientGender === 'F' ?
                                            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="#FE9696" />
                                            </svg>
                                            :
                                            <svg width="15" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="#A9C6FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        }

                                    </p>
                                    {/* <p className={'text-sm text-gray-500  '}>
                                        <b>DOB: </b>{couple?.patientDOB}   <b>({couple?.patientResAge.split(',')[0]}) </b>
                                    </p> */}
                                </div>
                            </div>

                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className='cursor-pointer text-sbase font-medium   text-gray-700'>Partner
                                </p>
                                <div className='flex pl-2 flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500 flex items-center gap-1 '}>
                                        {couple?.partnerFullName}

                                        {couple?.partnerGender === 'F' ?
                                            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="#FE9696" />
                                            </svg>
                                            :
                                            <svg width="15" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="#A9C6FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        }
                                    </p>
                                    {/* <p className={'text-sm text-gray-500  '}>
                                        <b>DOB: </b> {couple?.partnerDOB} <b>({couple?.partnerAge.split(',')[0]}) </b>
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex items-center  py-1  border-b-[1px]  border-b-gray-200    w-1/2 '>
                                <p className=' text-sm font-medium w-10  text-gray-700'>DOB :</p>
                                <div className='flex pl-2 flex-wrap justify-between items-center'>
                                    <p className={'text-sm text-gray-500  '}>
                                        {couple?.patientDOB}   <b>({couple?.patientResAge.split(',')[0]}) </b>
                                    </p>
                                </div>
                                {/* <p className={'pl-2 text-sm text-gray-500  '}>  {couple?.patientDOB}   <b>({couple?.patientResAge.split(',')[0]}) </b></p> */}

                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <p className=' text-sm font-medium w-10  text-gray-700'>DOB :</p>
                                <div className='flex pl-2 flex-wrap justify-between items-center '>
                                    <p className={'text-sm text-gray-500  '}>
                                        {couple?.partnerDOB}   <b>({couple?.partnerAge.split(',')[0]}) </b>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <div className='flex  flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>Mobile : </b>
                                        {couple?.PatientPhoneMobile}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>Home : </b>
                                        {couple?.PartnerPhoneHome}
                                    </p>
                                </div>
                            </div>
                            <div className=' text-left  mx-2 flex  py-1  border-b-[1px]  border-b-gray-200   w-1/2 '>
                                <div className='flex  flex-wrap justify-between items-center w-full'>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>Mobile : </b>
                                        {couple?.PartnerPhoneMobile}
                                    </p>
                                    <p className={'text-sm text-gray-500  '}>
                                        <b>Home : </b>
                                        {couple?.PartnerPhoneHome}
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className='flex w-full'>
                            <div className=' text-left  mx-2 flex  py-1    w-1/2 '>
                                <p className=' text-sm font-medium w-1/3 text-gray-500'><b>Patient Addres : </b></p>
                                <p className={'pl-2 text-sm text-gray-500 text-end w-2/3'}> {couple?.patientAddress}</p>

                            </div>
                            <div className=' text-left  mx-2 flex  py-1  w-1/2 '>
                                <p className=' text-sm font-medium   w-1/3  text-gray-500'><b>Partner Address</b></p>
                                <p className={'pl-2 text-sm text-gray-500  w-2/3  text-end'}> {couple?.partnerAddress}</p>

                            </div>
                        </div>
                    </div>



                    {!!!showPreview && <label className={'mr-4 font-bold text- xl ' + theme.txt_color + ' border-b-[3px] border-b-amber-500 '}>OHSS MONITORING Reports</label>
                    }

                    {!!!showPreview && <div className='w-full flex  flex-wrap py-2 items-between  '>
                        {/* Primary Information */}
                        <div className={'bg-[#5599ae] flex justify-between text-white text-left p-2 rounded-t w-full'}> <p className='text-sbase font-medium'>Relevant cycle details</p></div>
                        <div className='w-full shadow flex flex-wrap font-medium bg-white   '>
                            <div className={'w-1/3 text-left p-2  px-2 flex flex-wrap border-b-[2px] border-r-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>FSH Dose</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{artDetails?.fsh_dose}</p>
                            </div>
                            <div className={'w-1/3 text-left  p-2  flex flex-wrap border-b-[2px] border-r-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>hCG Administered</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>{artDetails?.hcg_admin_date}</p>
                            </div>
                            <div className={'w-1/3 text-left  p-2  flex flex-wrap border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>hCG Type</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>
                                    {artDetails?.hcg_type}
                                    {/* {procedureTypes.length > 0 ? procedureTypes?.filter(x => x.value === treatmentPlanObj[0].procedureTypeId)[0]?.label : ''}
                                    {treatmentPlanObj.procedureTypeName} */}
                                </p>
                            </div>
                            <div className={'w-1/3 text-left flex flex-wrap p-2  flex  border-b-[2px]  border-r-[2px] border-b-gray-200   ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Date of EPU</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>
                                    {artDetails?.epu_date}

                                </p>
                            </div>
                            <div className={'w-1/3 text-left  p-2  flex flex-wrap border-b-[2px] border-r-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Procedure</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>
                                    {artDetails?.procedure}

                                </p>
                            </div>
                            <div className={'w-1/3 text-left  p-2  flex flex-wrap border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'>Max. Oestradiol prior to EPU</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>
                                    {artDetails?.max_estradiol}
                                </p>
                            </div>
                            <div className={'w-1/3 text-left  p-2  flex flex-wrap  border-r-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'># Oocytes collected</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>
                                    {artDetails?.num_oocytes}

                                </p>
                            </div>
                            <div className={'w-1/3 text-left  p-2  flex flex-wrap border-r-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                                <p className='cursor-pointer text-sbase font-bold w-52 text-gray-700'># Embryos transferred</p>
                                <p className='cursor-pointer text-sbase  text-gray-700 flex items-center'>
                                    {artDetails?.num_embryos}

                                </p>
                            </div>
                        </div>
                    </div>}


                    {/* {ohhsDetails && ohhsDetails?.map((item) => { item.vomiting })} */}
                    <div className={'   w-full mt-1'}>
                        <label className={theme.txt_color + ' font-bold cursor-pointer  border-b-[3px] border-b-amber-500'}>Previous OHSS Follow-ups</label>
                        {ohhsDetails && ohhsDetails?.length > 0 && ohhsDetails.filter(x => x.ohssmonitoringid > 0) ?
                            <div className={theme.thumb + ' ' + theme.track + ' mt-3  overflow-x-auto scrollbar scrollbar-thin w-full bg-white'} >
                                {/* <div className={'  w-full  flex  py-1 text-white rounded bg-[#5e5e5e]'}> */}
                                <div className=' w-full  flex  py-1 text-white rounded bg-[#5e5e5e]'>
                                    <div style={{ width: '12%', height: "100% " }} className={' flex item-center justify-center py-1 '}>
                                        <p className='text-sm font-medium '>Date & Time</p>
                                    </div>
                                    <div className='border-l w-[88%] flex'>
                                        {/* <div style={{ width: '12%', height: "100% " }} className={' flex item-center justify-center  py-1 px-2 '}>
                                            <p className='text-sm font-bold px-2 '>Date & Time </p>
                                        </div> */}
                                        <div style={{ width: '13%', height: "100% " }} className={' py-1 px-2  text-center'}>
                                            <p className='text-sm font-bold '>Nausea</p>
                                        </div>
                                        <div style={{ width: '12%', height: "100% " }} className={'  py-1 px-2 text-center'}>
                                            <p className='text-sm font-bold  '>Vomiting</p>
                                        </div>
                                        <div style={{ width: '12%', height: "100% " }} className={'  py-1 px-2 text-center '}>
                                            <p className='text-sm font-bold  '>Breathlessness</p>
                                        </div>
                                        <div style={{ width: '12%', height: "100% " }} className={'  py-1 px-2 text-center'}>
                                            <p className='text-sm font-bold '>Pain</p>
                                        </div>
                                        <div style={{ width: '12%', height: "100% " }} className={'  py-1 px-2 text-center'}>
                                            <p className='text-sm font-bold '>Urine Output</p>
                                        </div>
                                        <div style={{ width: '13%', height: "100% " }} className={'  py-1 px-2 text-center'}>
                                            <p className='text-sm font-bold '>Diarrhoea</p>
                                        </div>
                                        <div style={{ width: '13%', height: "100% " }} className={'  py-1 px-2 text-center'}>
                                            <p className='text-sm font-bold  '>Umbilicus Girth</p>
                                        </div>

                                        <div style={{ width: '13%', height: "100% " }} className={'  py-1 px-2 text-center'}>
                                            <p className='text-sm font-bold  item-center'>Weight Gain</p>
                                        </div>
                                    </div>
                                </div>
                                {ohhsDetails && ohhsDetails?.length > 0 && ohhsDetails.filter(x => x.ohssmonitoringid > 0)?.map((item, index) => (
                                    <div className={theme.hoverBg + ' cursor-pointer w-full flex tem-center justify-center rounded border mb-1'}>
                                        <div style={{ width: '12%', height: "100% " }} className={' flex item-center justify-center p-1 '}>
                                            <p className='text-xss text-center font-medium text-gray-700'>{item.addedon}</p>
                                        </div>
                                        <div className='border-l w-[88%] '>
                                            < div className={' w-full   flex  mb-0.5   rounded   items-center border-b'} >

                                                <div style={{ width: '13%', height: "100% " }} className={` border  py-1 text-center`} >
                                                    <p className='text-xss font-medium text-gray-700 px-2'>{Object.keys(ohssType).find(key => ohssType[key] === item?.nausea)}
                                                    </p>
                                                </div>
                                                <div style={{ width: '12%', height: "100% " }} className={` border  py-1 text-center`} >
                                                    <p className='text-xss font-medium text-gray-700 px-2'>{Object.keys(ohssType).find(key => ohssType[key] === item?.vomiting)}</p>
                                                </div>
                                                <div style={{ width: '12%', height: "100% " }} className={` border  py-1 text-center`} >
                                                    <p className='text-xss font-medium text-gray-700 px-2'>{Object.keys(ohssType).find(key => ohssType[key] === item?.breathless)}</p>
                                                </div>
                                                <div style={{ width: '12%', height: "100% " }} className={` border  py-1 text-center`} >
                                                    <p className='text-xss font-medium text-gray-700 px-2'>{Object.keys(ohssType).find(key => ohssType[key] === item?.pain)}</p>
                                                </div>
                                                <div style={{ width: '12%', height: "100% " }} className={` border  py-1 text-center`} >
                                                    <p className='text-xss font-medium text-gray-700 px-2'>{Object.keys(UrineOutputType).find(key => UrineOutputType[key] === item?.urineoutput)}</p>
                                                </div>
                                                <div style={{ width: '13%', height: "100% " }} className={` border  py-1 text-center`} >
                                                    <p className='text-xss font-medium text-gray-700 px-2'>{Object.keys(ohssType).find(key => ohssType[key] === item?.diarrhoea)}</p>
                                                </div>
                                                <div style={{ width: '13%', height: "100% " }} className={` border  py-1 text-center`} >
                                                    <p className='text-xss font-medium text-gray-700 px-2'>{Object.keys(ohssType).find(key => ohssType[key] === item?.umbilicusgirth)}</p>
                                                </div>
                                                <div style={{ width: '13%', height: "100% " }} className={` border  py-1 text-center`}>
                                                    <p className='text-xss font-medium text-gray-700 px-2'>{Object.keys(ohssType).find(key => ohssType[key] === item?.weight)}</p>
                                                </div>

                                            </div>
                                            <p className='text-xss w-full font-medium text-gray-700 p-2'>{item.comments ? item.comments + " - " : ''}{item.displaySummary}{item.hospital ? " - " + item.hospital : ''}</p>
                                        </div>

                                    </div>
                                ))}
                            </div>
                            :
                            <div className="flex justify-center w-[99%] self-end shadow p-2 mt-4 m-2 mr-0 text-[#664d03] border rounded-lg bg-red-100  " >
                                <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#664d03" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <div className="ms-3 text-sm  font-medium text-[#664d03]">
                                    <i> ~ No Follow-ups have been entered yet. ~</i>
                                </div>
                            </div>
                        }
                        {/* {ohhsDetails?.map((item) => (
                            <div className='w-full mb-2 border '>
                                <div className={((theme.bg_Light + " " + theme.txt_color + '  font-medium')) + ' flex rounded-t text-xss text-left p-1'}>
                                    <label className='w-4/5'>
                                        Blood Scan {(item.testdate) ? 'on ' + getCorrectDate(item.testdate) : ''}
                                    </label>
                                    <label className='w-2/5 text-right'>
                                        {item.addedbyuser ? 'by : ' + item.addedbyuser : ''}
                                    </label>
                                </div>
                                <div className='bg-white rounded  flex flex-wrap p items-center w-full '>
                                    <div className='w-full flex   '>
                                        <div className='w-[40%] flex   border   py-0.5'>
                                            <label className='text-xss text-black px-2  w-16 '>Day</label>
                                            <label className='text-xss text-gray-500  text-center '>{item?.testdayofcycle} </label>
                                        </div>
                                        <div className='w-[30%] flex  border   py-0.5'>
                                            <label className='text-xss text-black  px-2 w-16 '>Test Date</label>
                                            <label className='text-xss text-gray-500  px-2  text-center '>{getCorrectDate(item.testdate)} </label>
                                        </div>
                                        <div className='w-[30%] flex    border   py-0.5'>
                                            <label className='text-xss text-black px-2  w-24 '>E2 pmol/L</label>
                                            <label className='text-xss text-gray-500   px-2 rounded-md '>{item.e2_dose} </label>
                                        </div>


                                    </div>
                                    <div className='w-full flex   '>
                                        {/* <div className='w-[20%] flex  border   py-0.5'>
                                            <label className='text-xss text-black  px-2 w-16 '>Test Date</label>
                                            <label className='text-xss text-gray-500   text-center '>{getCorrectDate(item.testdate)} </label>
                                        </div> 
                                        <div className='w-[40%] flex     border   py-0.5'>
                                            <label className='text-xss text-black  px-2 w-16 '>Clinic</label>
                                            <label className='text-xss text-gray-500      '> {item?.ClinicName}</label>
                                        </div>

                                        <div className='w-[30%] flex    border   py-0.5'>
                                            <label className='text-xss text-black px-2   w-24 '>P4 nmol/L</label>
                                            <label className='text-xss text-gray-500 text-center  rounded-md px-2 '>{item.p4_dose} </label>
                                        </div>
                                        <div className='w-[30%] flex    border   py-0.5'>
                                            <label className='text-xss text-black px-2  w-24 '>LH IU/L</label>
                                            <label className='text-xss text-gray-500 text-center  px-2'>{item.lh_dose} </label>
                                        </div>
                                    </div>
                                    <div className='w-full flex   '>
                                        <div className='w-[40%] flex     border   py-0.5'>
                                            <label className='text-xss text-black   px-2 '>Procedure Location</label>
                                            <label className='text-xss text-gray-500     '> {item?.HospitalName}</label>
                                        </div>


                                        <div className='w-[30%] flex  border   py-0.5'>
                                            <label className='text-xss text-black px-2 w-24 '>FSH IU/L</label>
                                            <label className='text-xss text-gray-500  px-2 rounded-md '>{item.fsh_dose} </label>

                                        </div>

                                        <div className='w-[30%] flex    border   py-0.5'>
                                            <label className='text-xss text-black px-2  w-24 '>HCG IU/L</label>
                                            <label className='text-xss text-gray-500 rounded-md  px-2 text-center '>{item.hcg_dose} </label>

                                        </div>
                                    </div>


                                    <div className='w-full flex   p-2'>
                                        <label className='text-xss text-black  '>Comments</label>
                                        <label className='text-xss text-gray-500   rounded-md px-2 '>{item.comments ? item.comments : " No Comments....."} </label>
                                    </div>
                                </div>
                            </div>
                        ))
                        } */}
                    </div>
                    {showPreview === true && <> <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                        If you have any questions or you would like to discuss with Patient Service staff, please contact Melbourne On phone number 1300 781 483.</p>
                        <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                            Kind regards,<br />
                            City Fertility Center</p></>}
                </main >
                <footer className='flex mt-3 p-2 hidden'>
                    <div className='w-full flex items-center'>
                        <img className='rounded-full  h-14' src={logo} alt={"logo"} />
                        <div className='border-gray-200  p-1 ml-2 '>
                            <p className='text-xss text-left  m-0 text-gray-700 font-medium'>
                                Document generated on 19-JUN-2023 16:10:05 AEST<br />
                                Do NOT rely solely on the information on this print-out. Always check the Current Treatment Plan in the FSF Database for changes.<br />
                                Form version 8.1 saved on 31-OCT-2022 09:41:04 AEST by Michelle Brown. </p>
                        </div>
                    </div>
                </footer>
            </div >
        </div >
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OhssPrint)