const APIURLS = {
    oHSSUrls: {
        sendEmail: 'UserManagment/SendEmail',
    },

    nursingActionUrls: {
        GetNursingActionDetails: 'Actions/GetNursingActionDetails',
        SaveCycleAction: 'Actions/SaveCycleAction',
        GetAllCycleActionType: 'Actions/GetAllCycleActionType',
    },
};

export default APIURLS;